import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header><a href={'/'}>rimota.ltd</a></header>
        <footer>
            <p>
                under construction
            </p>
            <p className='mail' onClick={() => window.location.href = 'mailto:info@rimota.ltd'}>
                info@rimota.ltd
            </p>
            </footer>
    </div>
  );
}

export default App;
